import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="pb-4 px-4 md:px-16 xl:px-32">
          <h2 className="font-poppins font-semibold text-2xl">Blog Posts</h2>
        </div>
        <div className="pb-8 md:pb-20 px-4 md:px-16 xl:px-32">
          <BlogRoll limit={1000} />
        </div>
      </Layout>
    );
  }
}
